import Link from 'next/link';

const PageSectionHeader = ({
  title,
  linkText,
  href,
  target,
  variant = 'default',
}: {
  title: string;
  href?: string;
  target?: string;
  linkText?: string;
  variant?: 'default' | 'secondary' | 'centered';
}) => {
  return variant === 'default' ? (
    <div className="flex w-full flex-wrap items-center justify-between px-8 sm:px-0">
      <h3 className="grow text-[2.4rem] font-semibold text-inherit sm:text-[3rem]">
        {title}
      </h3>

      {linkText && href && (
        <Link
          href={href}
          target={target ?? '_self'}
          className="transition-color text-[1.6rem] font-semibold text-brand-secondary transition-colors hover:text-brand-primary sm:text-[2rem]"
        >
          {linkText}
        </Link>
      )}
    </div>
  ) : variant === 'secondary' ? (
    <div className="mb-[2.4rem] items-center sm:mb-[4rem] sm:flex sm:justify-between">
      <h2 className="mx-auto mb-[1.6rem] block w-fit text-center text-[2.4rem] font-semibold sm:m-0 sm:inline-block sm:text-[3.2rem]">
        {title}
      </h2>
      <div className="mx-auto block w-fit text-center sm:m-0 sm:inline-block">
        {linkText && href && (
          <Link
            href={href}
            className="transition-color font-semibold text-brand-secondary transition-colors hover:text-brand-primary"
          >
            {linkText}
          </Link>
        )}
      </div>
    </div>
  ) : (
    <div className="flex w-full items-start justify-center px-8 sm:mx-auto sm:max-w-content">
      <div className="flex flex-col items-center gap-y-8">
        <h2 className="mb-[1.6rem] block w-fit text-center text-[2.4rem] font-semibold sm:m-0 sm:inline-block sm:text-[3.2rem]">
          {title}
        </h2>
        <div className="hidden sm:block">
          {linkText && href && (
            <Link
              href={href}
              className="transition-color font-semibold text-brand-secondary transition-colors hover:text-brand-primary"
            >
              {linkText}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageSectionHeader;
